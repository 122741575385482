<template>
  <div class="bk-page" id="cashCollectionPlan">
    <Form
      class="bk-page-lst-search"
      @submit.native.prevent
      inline
      :label-width="90"
    >
      <FormItem label="委托单：">
        <Input
          :value="searchData1.OrderCode"
          readonly
          clearable
          @on-clear="unPickOrder"
        >
          <Button slot="append" icon="ios-more" @click="toPickOrder"></Button>
        </Input>
      </FormItem>
      <FormItem :label-width="0" class="btns">
        <Button type="primary" @click="onSearch">搜索</Button>
        <Button type="default" @click="reSearchData">重置</Button>
        <Button type="primary" ghost @click="toAdd"
          ><Icon type="md-add" />新增</Button
        >
      </FormItem>
    </Form>
    <div class="bk-page-lst">
      <Table
        :loading="tableLoading"
        :columns="tableCols"
        :data="tableData"
      ></Table>
      <Page
        :total="recordCount"
        :current="pageIndex"
        :page-size="pageSize"
        transfer
        show-total
        show-sizer
        show-elevator
        @on-change="(index) => onChangePage(index, 0)"
        @on-page-size-change="(size) => onChangePage(1, size)"
      />
    </div>
    <pick-order ref="PickOrder" @on-ok="onPickOrder" />
    <add ref="add" @on-add="onAdd" />
    <edit ref="edit" @on-upd="reqTable" />
    <dtl ref="dtl" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import add from "./send-add.vue";
import edit from "./send-edit.vue";
import dtl from "./send-dtl.vue";
export default {
  components: { PickOrder, add, edit, dtl },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "委托单", key: "OrderCode" },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.invoiceSend.StatusBadge[params.row.Status],
                text: this.$designConst.invoiceSend.Status[params.row.Status],
              },
            });
          },
        },
        { title: "收件人信息", key: "PostAddress" },
        { title: "寄送时间", key: "PostTime" },
        {
          title: "操作",
          width: 80,
          render: (h, params) => {
            let upd = params.row.Status <= 1;
            let a = [];
            a.push(
              h("Icon", {
                props: { type: "md-create", size: 18 },
                attrs: { title: upd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    upd
                      ? this.$refs.edit.open(params.row.Id)
                      : this.$refs.dtl.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            a.push(
              h("Icon", {
                props: { type: "md-close", size: 18 },
                attrs: { title: "删除" },
                style: { cursor: "pointer", color: "#ed4014" },
                on: {
                  click: () => {
                    this.toDel(params.row);
                  },
                },
              })
            );
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.initSearch();
    });
    if (this.$route.params.re == 1) {
      this.initSearch();
    }
  },
  methods: {
    initSearch() {
      this.reSearchData();
      if (this.$store.state.order.Id) {
        this.searchData1.OrderId = this.$store.state.order.Id;
        this.searchData1.OrderCode = this.$store.state.order.Code;
      }
      this.pageIndex = 1;
      this.onSearch();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reqTable() {
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsInvoiceSend/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 选择委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
      } else {
        this.searchData1.OrderId = "";
        this.$set(this.searchData1, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
    },
    // 新增
    toAdd() {
      this.$refs.add.open();
    },
    onAdd(id) {
      this.initSearch();
      this.$refs.edit.open(id);
    },
    // 删除
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除",
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsInvoiceSend/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.bk-page-lst-search {
  display: flex;
  flex-wrap: wrap;
}
.bk-page-lst-search > * {
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 300px;
}
.bk-page-lst-search .btns button + button {
  margin-left: 10px;
}
</style>
<style lang="less">
#cashCollectionPlan {
  .ivu-badge-status {
    position: relative;
  }
  .ivu-badge-status-dot {
    position: absolute;
    top: 8px;
  }
  .ivu-badge-status-text {
    margin: 0;
    text-indent: 10px;
  }
}
</style>

