<template>
  <div>
    <Drawer
      v-model="flag"
      class="bk-sub-drawer invoice-send-edit-drawer"
      title="详情"
      :transfer="false"
      :mask-closable="false"
      width="400"
      @on-close="close"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          @submit.native.prevent
          :model="formd"
          :rules="rules"
          ref="form"
          :label-width="110"
        >
          <FormItem label="状态：" prop="Status" required>
            <Select v-model="formd.Status">
              <Option :value="0">草稿</Option>
              <Option :value="1">待签收</Option>
            </Select>
          </FormItem>
          <FormItem label="委托单：" prop="OrderId" required>
            <Input
              :value="formd.OrderCode"
              readonly
              clearable
              @on-clear="unPickOrder"
            >
              <Button
                slot="append"
                icon="ios-more"
                @click="toPickOrder"
              ></Button>
            </Input>
          </FormItem>
          <FormItem label="寄送时间：" prop="PostTime" required>
            <DatePicker
              v-model="formd.PostTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
            ></DatePicker>
          </FormItem>
          <FormItem label="收件人信息：" prop="PostAddress" required>
            <Input
              v-model="formd.PostAddress"
              type="textarea"
              placeholder="称呼、联系方式、地址"
            />
          </FormItem>
          <FormItem label="内部备注：">
            <Input v-model="formd.Remark" type="textarea" />
          </FormItem>
          <FormItem label="发票附件：" style="margin-bottom: 0"></FormItem>
        </Form>
        <div class="attas">
          <Spin class="bk-loading" fix v-if="imageLoading != 0">
            <div>
              <Icon type="ios-loading"></Icon>
              <div v-if="imageLoading == -1">删除中...</div>
              <div v-if="imageLoading == 1">加载中...</div>
              <div v-if="imageLoading == 2">提交中...</div>
            </div>
          </Spin>
          <input
            type="file"
            accept="image/*"
            ref="attasInput"
            multiple
            @change="onFiles"
            style="display: none"
          />
          <div
            v-if="$refs.attasInput"
            class="bk-add-file"
            @click="
              () => {
                $refs.attasInput.click();
              }
            "
            style="margin-right: 10px"
          >
            <Icon type="md-add" size="20" color="#808695"></Icon>
          </div>
          <div class="atta" v-for="(img, i) in imageList" :key="`img-${i}`">
            <img :src="img" alt="" @click="viewImg(i)" />
            <Icon
              class="icon-remove"
              type="md-close-circle"
              color="#ed4014"
              size="20"
              @click="toRemoveImg(i)"
            />
          </div>
        </div>
      </div>
      <div class="bk-drawer-footer">
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Drawer>
    <pick-order ref="PickOrder" @on-ok="onPickOrder" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
export default {
  components: { PickOrder },
  data() {
    return {
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        Status: [{ required: true, type: "number", message: " " }],
        OrderId: [{ required: true, type: "number", message: " " }],
        PostTime: [
          {
            validator: (rule, value, callback) => {
              this.formd.PostTime ? callback() : callback(new Error(" "));
            },
          },
        ],
        PostAddress: [{ required: true, message: " " }],
      },
      imageLoading: 0, // -1：删除；1：加载；2：新增；
      imageList: [],
      imageList_: [],
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.req();
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsInvoiceSend/GetVo", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            this.reqAttas();
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      let params = this.$util.copy(this.formd);
      if (typeof this.formd.PostTime == Object) {
        params.PostTime = this.formd.PostTime.format("yyyy-MM-dd hh:mm");
      }
      this.posting = true;
      this.$axios
        .post("/Api/BtmsInvoiceSend/Update", params)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("保存成功");
            this.$emit("on-upd");
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
      } else {
        this.formd.OrderId = "";
        this.$set(this.formd, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
    }, // 附件
    reqAttas() {
      this.imageLoading = 1;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 13,
            OrderId: this.formd.OrderId,
            DataId: this.formd.Id,
          },
        })
        .then((res) => {
          this.imageLoading = 0;
          if (res.result) {
            this.imageList = res.data.map((v) => v.Url);
            this.imageList_ = res.data;
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.imageLoading = 0;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onFiles(e) {
      if (e.target.files.length > 0) {
        let files = []
        for (let i = 0; i < e.target.files.length; i++) {
          files.push(e.target.files[i]);
        }
        this.saveAttas(files);
        e.target.value = ""
      }
    },
    saveAttas(files) {
      let formdata = new FormData();
      formdata.append("OrderId", this.formd.OrderId);
      formdata.append("DataId", this.formd.Id);
      formdata.append("Type", 13);
      let hasFile = false;
      for (let a of files) {
        if (a.Id == undefined) {
          formdata.append("File", a);
          hasFile = true;
        }
      }
      if (hasFile) {
        this.imageLoading = 2;
      } else {
        return;
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/File/PostObject", formdata, config)
        .then((res) => {
          this.imageLoading = 0;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttas();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.imageLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imageList, i);
    },
    toRemoveImg(i) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除图片",
        onOk: () => {
          this.removeImg(i);
        },
      });
    },
    removeImg(i) {
      if (this.imageList_[i].Id) {
        this.imageLoading = -1;
        this.$axios
          .post("/Api/DataAttachment/Delete", { Ids: this.imageList_[i].Id })
          .then((res) => {
            if (res.result) {
              this.reqAttas();
            } else {
              this.imageLoading = 0;
              this.$Message.error(res.errormsg);
            }
          })
          .catch((err) => {
            this.imageLoading = 0;
            this.$Message.error(err.errormsg);
          });
      } else {
        this.imageList.splice(i, 1);
        this.imageList_.splice(i, 1);
      }
    },
  },
};
</script>
<style lang="less">
.invoice-send-edit-drawer {
  .ivu-form-item {
    margin-bottom: 10px;
  }
  .attas {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 5px 0 5px 20px;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
  .atta:last-child {
    margin-right: 0;
  }
}
</style>