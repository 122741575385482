<template>
  <div>
    <Modal
      class="invoice-send-add-modal"
      v-model="flag"
      title="发票寄送[新增]"
      width="400"
      @on-cancel="close"
    >
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form">
        <FormItem label="委托单：" prop="OrderId" required>
          <Input
            :value="formd.OrderCode"
            readonly
            clearable
            @on-clear="unPickOrder"
          >
            <Button slot="append" icon="ios-more" @click="toPickOrder"></Button>
          </Input>
        </FormItem>
        <FormItem label="寄送时间：" prop="PostTime" required>
          <DatePicker
            v-model="formd.PostTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
          ></DatePicker>
        </FormItem>
        <FormItem label="收件人信息：" prop="PostAddress" required>
          <Input
            v-model="formd.PostAddress"
            type="textarea"
            placeholder="称呼、联系方式、地址"
          />
        </FormItem>
        <FormItem label="内部备注：">
          <Input v-model="formd.Remark" type="textarea" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" :loading="posting" @click="ok">保存为草稿</Button>
      </div>
    </Modal>
    <pick-order ref="PickOrder" @on-ok="onPickOrder" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
export default {
  components: { PickOrder },
  data() {
    return {
      flag: false,
      posting: false,
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        PostTime: [
          {
            validator: (rule, value, callback) => {
              this.formd.PostTime ? callback() : callback(new Error(" "));
            },
          },
        ],
        PostAddress: [{ required: true, message: " " }],
      },
    };
  },
  methods: {
    open() {
      this.formd = {
        Status: 0,
        PostTime: new Date(),
      };
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      let params = this.$util.copy(this.formd);
      params.PostTime = this.formd.PostTime.format("yyyy-MM-dd hh:mm");
      this.posting = true;
      this.$axios
        .post("/Api/BtmsInvoiceSend/Add", params)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("新增成功");
            this.$emit("on-add", res.data);
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
      } else {
        this.formd.OrderId = "";
        this.$set(this.formd, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
    }
  },
};
</script>
<style lang="less">
.invoice-send-add-modal {
  .ivu-form-item {
    margin-bottom: 10px;
  }
}
</style>