<template>
  <div>
    <Drawer
      v-model="flag"
      class="bk-sub-drawer invoice-send-edit-drawer"
      title="详情"
      :transfer="false"
      :mask-closable="false"
      width="400"
      @on-close="close"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          @submit.native.prevent
          :label-width="110"
        >
          <FormItem label="状态：">
            <Select v-model="formd.Status">
              <Option :value="0">草稿</Option>
              <Option :value="1">待签收</Option>
            </Select>
          </FormItem>
          <FormItem label="委托单：">
            <Input
              :value="formd.OrderCode"
              readonly
              clearable
              @on-clear="unPickOrder"
            >
              <Button
                slot="append"
                icon="ios-more"
                @click="toPickOrder"
              ></Button>
            </Input>
          </FormItem>
          <FormItem label="寄送时间：">
            <DatePicker
              v-model="formd.PostTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
            ></DatePicker>
          </FormItem>
          <FormItem label="收件人信息：">
            <Input
              v-model="formd.PostAddress"
              type="textarea"
              placeholder="称呼、联系方式、地址"
            />
          </FormItem>
          <FormItem label="内部备注：">
            <Input v-model="formd.Remark" type="textarea" />
          </FormItem>
          <FormItem label="发票附件：" style="margin-bottom: 0"></FormItem>
        </Form>
        <div class="attas">
          <Spin class="bk-loading" fix v-if="imageLoading != 0">
            <div>
              <Icon type="ios-loading"></Icon>
              <div v-if="imageLoading == 1">加载中...</div>
            </div>
          </Spin>
          <div class="atta" v-for="(img, i) in imageList" :key="`img-${i}`">
            <img :src="img" alt="" @click="viewImg(i)" />
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      imageLoading: 0, // -1：删除；1：加载；2：新增；
      imageList: [],
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.req();
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.id = 0;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = {};
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsInvoiceSend/GetVo", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            this.reqAttas();
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择委托单
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
      } else {
        this.formd.OrderId = "";
        this.$set(this.formd, "OrderCode", "");
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
    }, // 附件
    reqAttas() {
      this.imageLoading = 1;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 13,
            OrderId: this.formd.OrderId,
            DataId: this.formd.Id,
          },
        })
        .then((res) => {
          this.imageLoading = 0;
          if (res.result) {
            this.imageList = res.data.map((v) => v.Url);
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.imageLoading = 0;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imageList, i);
    },
  },
};
</script>
<style lang="less">
.invoice-send-edit-drawer {
  .ivu-form-item {
    margin-bottom: 10px;
  }
  .attas {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 5px 0 5px 20px;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
  .atta:last-child {
    margin-right: 0;
  }
}
</style>